import * as React from "react";
import { SVGProps } from "react";

const BehanceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19.2}
        height={12}
        viewBox="0 0 19.2 12"
        {...props}
    >
        <path
            id="Icon_akar-behance-fill"
            data-name="Icon akar-behance-fill"
            d="M17.6,9.1H12V7.5h5.6Zm1.381,8c-.354,1.038-1.623,2.4-4.081,2.4s-4.451-1.383-4.451-4.54c0-3.128,1.86-4.736,4.373-4.736a4.014,4.014,0,0,1,4.3,3.541,10.712,10.712,0,0,1,.076,1.712H12.776a2.155,2.155,0,0,0,3.67,1.623h2.534Zm-6.149-3.2H16.8a1.788,1.788,0,0,0-1.982-1.775A1.833,1.833,0,0,0,12.832,13.9ZM5.173,19.49H0V7.517H5.562c4.381.065,4.464,4.355,2.176,5.525C10.507,14.05,10.6,19.49,5.173,19.49ZM2.4,12.3H5.267c2.006,0,2.325-2.4-.25-2.4H2.4Zm2.713,2.4H2.4v2.413H5.073C7.517,17.113,7.367,14.7,5.113,14.7Z"
            transform="translate(0 -7.5)"
        />
    </svg>
);


export default BehanceIcon;
