
import "./App.scss";
import Logo from "../src/assets/images/unco.svg";
import LogoMark from "../src/assets/images/half-logomark.svg";
import MiddleMark from "../src/assets/images/middle-white.svg";
import Seprator from "./components/Separator";
import Icons from "./components/Icons";

const App = () => {
	return (
		<main className="main">
			<div className="coming-soon-container">
				<div>
					<img className="logo" src={Logo} alt="UNCO" draggable="false" />
					<h1 className="header">in the works</h1>
					<p className="subheader">
						we specialize in both design and development by providing <br className="hide-mobile" />
						seamless solutions to ensure digital presence for our <br className="hide-mobile" />
						partners.
					</p>
					<button onClick={() => { window.location.href = "mailto:contact@unco.world" }}>
						get in touch
						<Seprator width={16} />
						<Icons.Redirect />
					</button>
				</div>
				<div className="connect">
					<p>connect with us</p>
					<ul className="social-icons">
						<li><Icons.LinkedIn /></li>
						<li><Icons.Behance /></li>
						<li><Icons.Facebook /></li>
						<li><Icons.Instagram /></li>
					</ul>
				</div>
			</div>
			<div className="background">
				<img className="logo-mark" src={LogoMark} alt="Logo Mark" draggable="false" />
				<img className="white-circle" src={MiddleMark} alt="" draggable="false" />
			</div>
		</main>
	)
}


export default App;