import * as React from "react";
import { SVGProps } from "react";

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      id="Icon_corebrands-instagram"
      data-name="Icon corebrands-instagram"
      d="M8,0c-2.175,0-2.446.011-3.3.047A5.94,5.94,0,0,0,2.763.419a3.924,3.924,0,0,0-1.417.925A3.879,3.879,0,0,0,.421,2.76,5.94,5.94,0,0,0,.049,4.7C.01,5.555,0,5.825,0,8s.011,2.446.047,3.3A5.954,5.954,0,0,0,.421,13.24a3.924,3.924,0,0,0,.925,1.417,3.879,3.879,0,0,0,1.417.925,6.021,6.021,0,0,0,1.943.373C5.557,15.992,5.827,16,8,16s2.446-.01,3.3-.047a6.034,6.034,0,0,0,1.943-.373,3.924,3.924,0,0,0,1.417-.925,3.858,3.858,0,0,0,.925-1.417,6.021,6.021,0,0,0,.373-1.943c.039-.852.047-1.122.047-3.3s-.011-2.446-.047-3.3a6.017,6.017,0,0,0-.373-1.943,3.924,3.924,0,0,0-.925-1.417A3.858,3.858,0,0,0,13.242.419,5.954,5.954,0,0,0,11.3.047C10.448.008,10.177,0,8,0ZM8,1.44c2.136,0,2.391.011,3.235.047a4.427,4.427,0,0,1,1.484.277,2.5,2.5,0,0,1,.922.6,2.466,2.466,0,0,1,.6.92,4.427,4.427,0,0,1,.277,1.484c.036.844.047,1.1.047,3.235s-.011,2.391-.05,3.235a4.552,4.552,0,0,1-.282,1.484,2.6,2.6,0,0,1-.6.922,2.524,2.524,0,0,1-.922.6,4.43,4.43,0,0,1-1.49.277c-.849.036-1.1.047-3.24.047s-2.391-.011-3.24-.05a4.6,4.6,0,0,1-1.49-.282,2.51,2.51,0,0,1-.92-.6,2.4,2.4,0,0,1-.6-.922,4.473,4.473,0,0,1-.282-1.49c-.029-.839-.042-1.1-.042-3.23s.014-2.391.042-3.24a4.479,4.479,0,0,1,.282-1.49,2.35,2.35,0,0,1,.6-.922,2.386,2.386,0,0,1,.92-.6,4.458,4.458,0,0,1,1.482-.279c.849-.031,1.1-.042,3.237-.042ZM8,3.894A4.107,4.107,0,1,0,12.109,8,4.106,4.106,0,0,0,8,3.894Zm0,6.774A2.667,2.667,0,1,1,10.669,8,2.665,2.665,0,0,1,8,10.668ZM13.235,3.73a.961.961,0,1,1-.962-.959A.962.962,0,0,1,13.235,3.73Z"
      transform="translate(-0.002)"
      fill="#131313"
    />
  </svg>
);


export default InstagramIcon;
