import * as React from "react";
import { SVGProps } from "react";

const Redirect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      id="Icon_material-arrow-outward"
      data-name="Icon material-arrow-outward"
      d="M7.473,9v2.769l13.285,0L7.5,25.048,9.447,27l13.26-13.278L22.7,26.991h2.765L25.473,9Z"
      transform="translate(-7.473 -9)"
    />
  </svg>
);


export default Redirect;
