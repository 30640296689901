import * as React from "react";
import { SVGProps } from "react";

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.5}
    height={16}
    viewBox="0 0 8.5 16"
    {...props}
  >
    <path
      id="Icon_corebrands-facebook-f"
      data-name="Icon corebrands-facebook-f"
      d="M16.3,9l.441-2.9H13.988V4.226a1.442,1.442,0,0,1,1.619-1.564H16.86V.2A15.165,15.165,0,0,0,14.636,0c-2.27,0-3.753,1.387-3.753,3.9V6.106H8.36V9h2.523v7h3.1V9Z"
      transform="translate(-8.36 -0.001)"
      fill="#131313"
    />
  </svg>
);


export default FacebookIcon;
