import Redirect from "./modules/RedirectIcon.tsx";
import LinkedIn from "./modules/LinkedInIcon.tsx";
import Behance from "./modules/BehanceIcon.tsx";
import Facebook from "./modules/FacebookIcon.tsx";
import Instagram from "./modules/InstagramIcon.tsx";

const Icons = {
    Redirect,
    LinkedIn,
    Behance,
    Facebook,
    Instagram
}


export default Icons;