import * as React from "react";
import { SVGProps } from "react";

const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15.999}
        height={16}
        viewBox="0 0 15.999 16"
        {...props}
    >
        <path
            id="Icon_corebrands-linkedin"
            data-name="Icon corebrands-linkedin"
            d="M13.63,13.635H11.263V9.922c0-.885-.018-2.023-1.237-2.023C8.792,7.9,8.6,8.859,8.6,9.856v3.778H6.234V6H8.51V7.041h.03a2.494,2.494,0,0,1,2.245-1.234c2.4,0,2.846,1.58,2.846,3.635v4.192ZM3.557,4.956A1.376,1.376,0,1,1,4.935,3.581,1.375,1.375,0,0,1,3.557,4.956Zm1.187,8.679H2.37V6H4.744ZM14.818,0H1.18A1.166,1.166,0,0,0,0,1.153V14.847A1.166,1.166,0,0,0,1.18,16H14.815A1.171,1.171,0,0,0,16,14.847V1.153A1.171,1.171,0,0,0,14.815,0Z"
            fill="#131313"
        />
    </svg>
);


export default LinkedInIcon;
